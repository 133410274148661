/**
 * @license Copyright 2019 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License. You may obtain a copy of the License at http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions and limitations under the License.
 */

.lhr-comparison-scores {
  display: flex;
  width: 100%;
  margin-top: var(--base-spacing);
}

.lhr-comparison-scores-item {
  /* properties that affect how it's rendered within its parent */
  flex-basis: 0;
  flex-grow: 1;

  /* properties that affect how its children are rendered */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lhr-comparison-scores-item__label {
  margin-top: var(--base-spacing);
  font-size: var(--gauge-title-font-size);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lhr-comparison-scores-item__delta {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: var(--base-spacing);
  padding: 0 var(--base-spacing);
  height: var(--build-score-delta-badge-height);
  border-radius: calc(var(--build-score-delta-badge-height) / 2);
  line-height: var(--build-score-delta-badge-height);

  background: var(--neutral-color);
  color: var(--inverted-text-color);

  font-family: var(--monospace-font-family);
  font-size: var(--monospace-font-size);
  text-align: center;
}

.lhr-comparison-scores-item__delta > .pwa-icon {
  margin-right: calc(var(--base-spacing) / 2);
}

.lhr-comparison-scores-item__delta > .pwa-icon:last-child {
  margin-right: 0;
}

.lhr-comparison-scores-item--pwa .lhr-comparison-scores-item__delta {
  background-color: var(--secondary-background-color);
  color: var(--base-text-color);
}
